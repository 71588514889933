import { ChangeEvent, FormEvent, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import useFindUser from 'api/useFindUser';

import useUserLSState from 'hooks/useUserLSState';

// import Audio from 'components/ui/Audio';
import Button from 'components/ui/Button';
import ContentCentered from 'components/ui/ContentCentered';
import Input from 'components/ui/Input';
import Text from 'components/ui/Text';

import { URLS } from 'components/App';

import * as Styled from './styles';

const Enteremail = () => {
  const { updateState, multiInfoUpdate } = useUserLSState();
  const [email, setemail] = useState('');
  const navigate = useNavigate();

  const findUser = useFindUser();

  const isButtonDisabled = useMemo(() => email.trim().length < 3 || email.trim().length > 256, [email]);

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (isButtonDisabled) return;

    findUser.mutateAsync(
      { email: email },
      {
        onSuccess: (response) => {
          console.log(response);
          if (response.exists) {
            console.log('response userId = ', response.userId);
            multiInfoUpdate({
              firstName: response?.userDocument?.firstName ?? '',
              lastName: response?.userDocument?.lastName ?? '',
              role: response?.userDocument?.userType ?? '',
              userId: response.userId ?? '',
              numFreeStoriesGranted: response.userDocument?.numFreeStoriesGranted,
              numFreeStoriesLeft: response.userDocument?.numFreeStoriesLeft,
            });
            navigate(URLS.userInfo);
          } else {
            console.log('response exists = ', response.exists);
            navigate(URLS.enterInfo);
          }
        },
      }
    );

    updateState('email', email.trim());
    // navigate(URLS.enterInfo);
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setemail(e.target.value);
  };

  return (
    <ContentCentered>
      <Styled.EnterName onSubmit={handleSubmit}>
        <Text variant="md" colorType="light">
          What is your email address?
        </Text>
        <Input
          className="EnterName__Input"
          type="email"
          placeholder="My email is..."
          value={email}
          onChange={handleChange}
        />
        <Button type="submit" disabled={isButtonDisabled}>
          Continue
        </Button>
      </Styled.EnterName>
    </ContentCentered>
  );
};

export default Enteremail;
