import Button from 'components/ui/Button';
import ContentCommon from 'components/ui/ContentCommon';
import type { ContentCommonProps } from 'components/ui/ContentCommon';
import Image from 'components/ui/Image';

import { ARROW_LEFT_ICON, ARROW_RIGHT_ICON } from 'assets/icons';
import LUCA from 'assets/images/luca.png';

import * as Styled from './styles';

type ContentLucaProps = ContentCommonProps & {
  onBack?: () => void | undefined;
  backButton?: React.ReactNode;
  onForward?: () => void | Promise<void> | undefined;
  onForwardProps?: React.ButtonHTMLAttributes<HTMLButtonElement>;
  onBackProps?: React.ButtonHTMLAttributes<HTMLButtonElement>;
  actions?: React.ReactNode;
  children: string | React.ReactNode;
};

const ContentLuca = ({
  onBack,
  backButton,
  onForward,
  onForwardProps,
  onBackProps,
  actions,
  children,
}: ContentLucaProps) => (
  <ContentCommon>
    <Styled.Wrapper>
      <Image className="ContentLuca__Image" src={LUCA} />
      <Styled.Content>{children}</Styled.Content>
      {actions ||
        ((backButton || onBack || onForward) && (
          <Styled.Actions>
            {backButton
              ? backButton
              : onBack && (
                  <Button onClick={onBack} iconLeft={ARROW_LEFT_ICON} {...onBackProps}>
                    Back
                  </Button>
                )}
            {onForward && (
              <Button onClick={onForward} iconRight={ARROW_RIGHT_ICON} {...onForwardProps}>
                Next
              </Button>
            )}
          </Styled.Actions>
        ))}
    </Styled.Wrapper>
  </ContentCommon>
);

export default ContentLuca;
