import { useNavigate } from 'react-router-dom';

import useUserLSState from 'hooks/useUserLSState';

import Button from 'components/ui/Button';
import ContentLuca from 'components/ui/ContentLuca/ContentLuca';

import { URLS } from 'components/App';
import Feed from 'components/Feed/Feed';

const PHRASES = [
  {
    id: 0,
    text: 'Congratulations! You finished the lesson!',
    audio: '',
    localAudio: 'final_screen_audio/final_screen_1.mp3',
  },
  {
    id: 1,
    text: 'This is the end of our journey, but we can start it again!',
    audio: '',
    localAudio: 'final_screen_audio/final_screen_2.mp3',
  },
];

const LessonFinish = () => {
  const { clearWordIndexes } = useUserLSState();
  const navigate = useNavigate();

  const startAgain = () => {
    clearWordIndexes();
    navigate(URLS.userInfo);
  };

  return (
    <ContentLuca
      actions={
        <Button style={{ margin: '0 auto' }} onClick={startAgain}>
          Go to menu
        </Button>
      }
    >
      <Feed phrases={PHRASES} />
    </ContentLuca>
  );
};

export default LessonFinish;
