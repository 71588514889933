import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useBoolean } from 'react-use';

import useUserLSState from 'hooks/useUserLSState';

import Button from 'components/ui/Button';
import ContentLuca from 'components/ui/ContentLuca';

import { URLS } from 'components/App';
import Feed from 'components/Feed';

import { ARROW_LEFT_ICON, ARROW_RIGHT_ICON } from 'assets/icons';

import * as Styled from './styles';

const UserExists = () => {
  const navigate = useNavigate();
  const { userState } = useUserLSState();
  const [, setIsFeedFull] = useBoolean(false);

  const phrases = useMemo(() => {
    if (userState?.numFreeStoriesLeft && userState?.numFreeStoriesLeft > 0)
      return [
        {
          id: 0,
          text: `Hi ${userState?.firstName}, welcome back!`,
          audio: '',
        },
        {
          id: 1,
          text: `You have ${userState?.numFreeStoriesLeft} out of ${userState?.numFreeStoriesGranted} free customized stories left for your playground account. Don’t hesitate to try them out!`,
          audio: '',
        },
        {
          id: 2,
          text: 'Or--it’s time to join the real LUCA journey!',
          audio: '',
        },
      ];
    else {
      return [
        {
          id: 0,
          text: `Hi ${userState?.firstName}, welcome back!`,
          audio: '',
        },
        {
          id: 1,
          text: `Congratulations! You’ve finished all the 10 free customized stories. Time to embark on the real LUCA journey!`,
          audio: '',
        },
        {
          id: 2,
          text: 'Or you can continue reading some non-customized stories.',
          audio: '',
        },
      ];
    }
  }, [userState?.firstName, userState?.numFreeStoriesGranted, userState?.numFreeStoriesLeft]);

  const readAgain = () => {
    navigate(URLS.menu);
  };

  const backToStart = () => {
    navigate(URLS.enterName);
  };

  const toSignUp = () => {
    window.location.href = 'https://mvp.luca.ai/sign-up';
  };

  const handleFeedFull = () => setIsFeedFull(true);

  return (
    <ContentLuca
      backButton={
        <Styled.FooterControlCol>
          <Styled.FooterControlTopRow>
            {userState?.numFreeStoriesLeft !== 0 && (
              <Button iconRight={ARROW_RIGHT_ICON} onClick={toSignUp}>
                Sign Up at LUCA
              </Button>
            )}
          </Styled.FooterControlTopRow>
          <Styled.FooterControl>
            <Button iconLeft={ARROW_LEFT_ICON} onClick={backToStart}>
              Back
            </Button>
            {userState?.numFreeStoriesLeft && userState?.numFreeStoriesLeft > 0 ? (
              <Button iconRight={ARROW_RIGHT_ICON} onClick={readAgain}>
                Continue Reading
              </Button>
            ) : (
              <Button iconRight={ARROW_RIGHT_ICON} onClick={toSignUp}>
                Sign Up at LUCA
              </Button>
            )}
          </Styled.FooterControl>
        </Styled.FooterControlCol>
      }
      onBack={backToStart}
      onForward={undefined}
    >
      <Feed phrases={phrases} onFeedFull={handleFeedFull}></Feed>
    </ContentLuca>
  );
};

export default UserExists;
