import { useMutation } from '@tanstack/react-query';
// import { CamelCaseKeys } from 'camelcase-keys';
import snakeCaseKeys from 'snakecase-keys';

import { apiPlaygroundClient } from 'lib/apiClient';

import { TUpdateInterestsPayload } from 'types';

const updateInterests = (payload: TUpdateInterestsPayload) =>
  apiPlaygroundClient.post('user/update/interest_answers/', snakeCaseKeys(payload, { deep: true }));

const useUpdateInterests = () => useMutation(updateInterests);

export default useUpdateInterests;
