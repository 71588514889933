import styled from 'styled-components';

export const FooterControlCol = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 36px;
`;

export const FooterControlTopRow = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`;

export const FooterControl = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;
