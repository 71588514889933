import { ChangeEvent, FormEvent, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import useUserLSState from 'hooks/useUserLSState';

import AgeOption from 'components/ui/AgeOption';
import Button from 'components/ui/Button';
import ContentCentered from 'components/ui/ContentCentered';
import { FormControl } from 'components/ui/FormGroup';
import Input from 'components/ui/Input';
import Text from 'components/ui/Text';

import { URLS } from 'components/App';

import { ARROW_LEFT_ICON, ARROW_RIGHT_ICON } from 'assets/icons';

import * as Styled from './styles';

const roles = ['parent', 'guardian', 'educator', 'other'];

type InfoPayload = {
  firstName: string;
  lastName: string;
  role: string;
  specifyRole: string;
};

const EnterInfo = () => {
  const { multiInfoUpdate } = useUserLSState();
  const [info, setInfo] = useState<InfoPayload>({
    firstName: '',
    lastName: '',
    role: 'parent',
    specifyRole: '',
  });
  const navigate = useNavigate();

  const isButtonDisabled = useMemo(
    () => info.firstName.trim().length < 3 || info.firstName.trim().length > 256,
    [info]
  );

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (isButtonDisabled) return;
    multiInfoUpdate({
      firstName: info.firstName,
      lastName: info.lastName,
      role: info.role === 'other' ? info.specifyRole : info.role,
    });
    navigate(URLS.selectAgeRange);
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setInfo((prev) => ({ ...prev, [name]: value }));
  };

  const handleRoleChange = (value: string) => {
    setInfo((prev) => ({ ...prev, role: value }));
    if (value !== 'other') setInfo((prev) => ({ ...prev, specifyRole: '' }));
  };

  return (
    <ContentCentered>
      <Styled.EnterName onSubmit={handleSubmit}>
        <Text variant="md" colorType="light">
          Welcome to LUCA Playground, our cherished new explorer!
        </Text>
        <Styled.FormContainer>
          <Styled.FormRow>
            <Text variant="md" colorType="light" className="Label__Container">
              What is your name?
            </Text>
            <Styled.FlexContainer>
              <Input
                className="EnterName__Input"
                placeholder="First name"
                name="firstName"
                value={info.firstName}
                onChange={handleChange}
              />
              <Input
                className="EnterName__Input"
                placeholder="Last name"
                name="lastName"
                value={info.lastName}
                onChange={handleChange}
              />
            </Styled.FlexContainer>
          </Styled.FormRow>
          <Styled.FormRow>
            <Text variant="md" colorType="light" className="Label__Container">
              Select the user type that best describes your identity
            </Text>
            <div className="SelectAgeRange__FormGroup">
              {roles.map((role: string) => (
                <FormControl
                  defaultChecked={role === info.role}
                  name="ageRange"
                  id={role}
                  value={role}
                  key={role}
                  onClick={() => handleRoleChange(role)}
                >
                  <AgeOption className="SelectAgeRange__AgeOption">{role}</AgeOption>
                </FormControl>
              ))}
              <Input
                className="SpecifyRole__Input"
                placeholder="Please specify..."
                name="specifyRole"
                value={info.specifyRole}
                disabled={info.role !== 'other'}
                onChange={handleChange}
              />
            </div>
          </Styled.FormRow>
        </Styled.FormContainer>
        <Styled.FooterNAvigation>
          <Button
            iconLeft={ARROW_LEFT_ICON}
            onClick={() => {
              console.log(info);
            }}
            disabled={isButtonDisabled}
          >
            Back
          </Button>
          <Button
            iconRight={ARROW_RIGHT_ICON}
            onClick={() => {
              console.log(info);
            }}
            type="submit"
            disabled={isButtonDisabled}
          >
            Next
          </Button>
        </Styled.FooterNAvigation>
      </Styled.EnterName>
    </ContentCentered>
  );
};

export default EnterInfo;
