import { useMutation } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import camelCaseKeys from 'camelcase-keys';
import snakeCaseKeys from 'snakecase-keys';

import { apiPlaygroundClient } from 'lib/apiClient';

import { TGetInterestsResponse } from 'types';

const getInterestsQuestions = async (payload: { userId: string }) => {
  const res = await apiPlaygroundClient.post<{ userId: string }, AxiosResponse<TGetInterestsResponse>>(
    `user/get/interest_group/`,
    snakeCaseKeys(payload, { deep: true })
  );
  return camelCaseKeys(res.data, { deep: true });
};

// caching lesson response data for 50 minutes
const useGetInterestsQuestions = () => useMutation(getInterestsQuestions);

export default useGetInterestsQuestions;
