import styled from 'styled-components';

import RecordingControl from 'components/ui/RecordingControl';

export const StoryFinishRecordingControl = styled(RecordingControl)`
  margin-top: 50px;
  justify-content: center;
`;

export const FooterControl = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;
