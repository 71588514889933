import { useQuery } from '@tanstack/react-query';
import camelCaseKeys, { CamelCaseKeys } from 'camelcase-keys';
import snakeCaseKeys from 'snakecase-keys';

import { apiPlaygroundClient } from 'lib/apiClient';

import { GetLessonResponse } from 'types';
import { LessonEntity } from 'types';

const getLesson = async (payload: { userId: string }) => {
  const res = await apiPlaygroundClient.post<GetLessonResponse>(
    `/user/get/next_lesson/`,
    snakeCaseKeys(payload, { deep: true })
  );
  return camelCaseKeys(res.data, { deep: true });
};

const useGetLesson = (payload: { userId: string }) =>
  useQuery(['getLesson', payload.userId], () => getLesson({ userId: payload.userId }));

// caching lesson response data for 50 minutes

export type LessonResponse = CamelCaseKeys<LessonEntity, true>;
export default useGetLesson;
