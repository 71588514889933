import styled from 'styled-components';

export const SelectAgeRange = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  padding-bottom: 20px;

  .SelectAgeRange__FormGroup {
    display: grid;
    gap: 32px;
    margin-bottom: 20px;
    width: 100%;
    grid-template-columns: repeat(4, 1fr);
  }

  .SelectAgeRange__AgeOption {
    padding: 24px 88px;
  }
`;
