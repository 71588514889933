import { useQuery } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import camelCaseKeys, { CamelCaseKeys } from 'camelcase-keys';
import snakeCaseKeys from 'snakecase-keys';

import { apiPlaygroundClient } from 'lib/apiClient';

import { StoryEntity } from 'types';

const getStory = async (payload: { userId: string }) => {
  const response = await apiPlaygroundClient.post<{ userId: string }, AxiosResponse<StoryEntity>>(
    `user/get/next_story/`,
    snakeCaseKeys(payload, { deep: true })
  );
  return camelCaseKeys(response.data, { deep: true });
};

// const useGetStory = () => useQuery(getStory);
const useGetStory = (payload: { userId: string }) =>
  useQuery(['getStory', payload.userId], () => getStory({ userId: payload.userId }));

export type StoryResponse = CamelCaseKeys<StoryEntity, true>;
export default useGetStory;
