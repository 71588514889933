import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useBoolean } from 'react-use';

import useUserLSState from 'hooks/useUserLSState';

import useRecordingStore from 'screens/Story/useRecordingStore';

import Button from 'components/ui/Button';
import ContentLuca from 'components/ui/ContentLuca';

import { URLS } from 'components/App';
import Feed from 'components/Feed';

import * as Styled from './styles';

const StoryFinish = () => {
  const navigate = useNavigate();
  const { userState } = useUserLSState();
  const [, setIsFeedFull] = useBoolean(false);
  const { reset: clearRecordings } = useRecordingStore();

  const phrases = useMemo(() => {
    return [
      {
        id: 0,
        text: 'Congratulations! You finished the story!',
        audio: '',
        localAudio: 'story_instruction/s2.mp3',
      },
      {
        id: 1,
        text: `You have ${userState?.numFreeStoriesLeft} customized stories (out of ${userState?.numFreeStoriesGranted}) for free. Feel free to subscribe to LUCA for unlimited customized stories, AI-assisted reading error analysis and personalized learning plans!`,
        audio: '',
      },
    ];
  }, [userState?.numFreeStoriesGranted, userState?.numFreeStoriesLeft]);

  const readAgain = () => {
    clearRecordings();
    navigate(URLS.menu);
  };

  const handleSignUp = () => {
    window.location.href = 'https://mvp.luca.ai/sign-up';
  };

  const handleFeedFull = () => setIsFeedFull(true);

  return (
    <ContentLuca
      backButton={
        <Styled.FooterControl>
          <Button onClick={handleSignUp}>Sign Up for LUCA</Button>
          <Button onClick={readAgain}>Continue with Playground</Button>
        </Styled.FooterControl>
      }
    >
      <Feed phrases={phrases} onFeedFull={handleFeedFull}></Feed>
    </ContentLuca>
  );
};

export default StoryFinish;
