import { useMutation } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import camelCaseKeys from 'camelcase-keys';
import snakeCaseKeys from 'snakecase-keys';

import { apiPlaygroundClient } from 'lib/apiClient';

import { TUserAddPayload, TUserAddResponse } from 'types';

const addUser = async (payload: TUserAddPayload) => {
  const res = await apiPlaygroundClient.post<TUserAddPayload, AxiosResponse<TUserAddResponse>>(
    'user/add/',
    snakeCaseKeys(payload, { deep: true })
  );
  return camelCaseKeys(res.data, { deep: true });
};
const useAddUser = () => useMutation(addUser);

export default useAddUser;
