import { useMutation } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import camelCaseKeys from 'camelcase-keys';
import snakeCaseKeys from 'snakecase-keys';

import { apiPlaygroundClient } from 'lib/apiClient';

import { TStoryCompletePayload, TStoryCompleteResponse } from 'types';

const storyComplete = async (payload: TStoryCompletePayload) => {
  const res = await apiPlaygroundClient.post<TStoryCompletePayload, AxiosResponse<TStoryCompleteResponse>>(
    'user/update/story_completion/',
    snakeCaseKeys(payload, { deep: true })
  );
  return camelCaseKeys(res.data, { deep: true });
};

const useStoryComplete = () => useMutation(storyComplete);

export default useStoryComplete;
