import { useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import renderSwitch from 'lib/renderSwitch';

import useGetLesson, { LessonResponse } from 'api/useGetLesson';

import useUserLSState from 'hooks/useUserLSState';

import type { AudioRef } from 'components/ui/Audio';
import ContentLesson from 'components/ui/ContentLesson';
import ErrorCard from 'components/ui/ErrorCard';
import Loader from 'components/ui/Loader';
import Text from 'components/ui/Text';

import { URLS } from 'components/App';
import Instruction from 'components/Instruction';

import * as Styled from './styles';

type LessonSoundContentProps = Pick<LessonResponse, 'sound' | 'letters'>;

const LessonSoundContent = ({ sound, letters }: LessonSoundContentProps) => {
  const navigate = useNavigate();
  const instructionAudioRef = useRef<AudioRef>(null);

  return (
    <ContentLesson
      instruction={
        <Instruction
          text="Click the box to hear the sound and repeat out loud"
          audio="lesson_instruction/l1.mp3"
          audioRef={instructionAudioRef}
        />
      }
      onBack={() => navigate(URLS.menu)}
      onForward={() => navigate(URLS.lessonWords)}
    >
      <Styled.AudioBox
        playingAudioRef={instructionAudioRef}
        audio={sound}
        className="LessonSound__AudioBox"
        activeClassName="active"
      >
        <Text style={{ fontSize: 290, lineHeight: 0.55 }}>{letters}</Text>
      </Styled.AudioBox>
    </ContentLesson>
  );
};

const LessonSound = () => {
  const { userState } = useUserLSState();
  const { data, isFetching, isError, refetch } = useGetLesson({ userId: userState?.userId ?? '' });

  const renderState = renderSwitch({
    loading: {
      condition: isFetching,
      render: <Loader />,
    },
    error: {
      condition: isError,
      render: (
        <ErrorCard style={{ marginTop: 60 }} onRetry={refetch} message="There was a problem retrieving this lesson" />
      ),
    },
    default: {
      condition: !!data,
      render: (
        <LessonSoundContent
          sound={data?.lessonContentsFormatted?.soundS3Path ?? ''}
          letters={data?.lessonContentsFormatted?.letters ?? ''}
        />
      ),
    },
  });

  return <>{renderState}</>;
};

export default LessonSound;
