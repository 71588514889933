import styled from 'styled-components';

export const EnterName = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 40px;
  padding-bottom: 20px;
  width: 100%;
  height: 100%;

  .EnterName__Input {
    height: fit-content;
    width: 100%;

    input {
      max-width: 400px;
      width: 100%;
    }
  }

  .Label__Container {
    max-width: 500px;
    width: 100%;
  }

  .SpecifyRole__Input {
    grid-column: 2 / 4;
    input {
      max-width: 100%;
    }
  }
`;

export const FormContainer = styled.div`
  max-width: 1350px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 32px;
  width: 100%;
  height: 100%;

  .SelectAgeRange__FormGroup {
    display: grid;
    gap: 32px;
    width: 100%;
    grid-template-columns: repeat(3, 1fr);
  }

  .SelectAgeRange__AgeOption {
    padding: 24px 24px;
  }
`;

export const FormRow = styled.div`
  display: grid;
  grid-template-columns: 480px auto;
  gap: 32px;
  align-items: center;
`;

export const FlexContainer = styled.div`
  display: flex;
  gap: 32px;
`;

export const FooterNAvigation = styled.div`
  display: flex;
  max-width: 1350px;
  width: 100%;
  justify-content: space-between;
`;
