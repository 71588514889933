import clsx from 'clsx';
import { useNavigate } from 'react-router-dom';

import renderSwitch from 'lib/renderSwitch';
import { getWordsFromText } from 'lib/utils';

import useGetLesson, { LessonResponse } from 'api/useGetLesson';
import useLessonComplete from 'api/useLessonComplete';

// import usePostLessonResults from 'api/usePostLessonResults';
import useUserLSState from 'hooks/useUserLSState';

import Button from 'components/ui/Button';
import Circle from 'components/ui/Circle/Circle';
import ContentLesson from 'components/ui/ContentLesson';
import ErrorCard from 'components/ui/ErrorCard';
import Loader from 'components/ui/Loader';
import Sentence from 'components/ui/Sentence';
import SoundCard from 'components/ui/SoundCard';
import Text from 'components/ui/Text';

import { URLS } from 'components/App';
import Instruction from 'components/Instruction';

import * as Styled from './styles';

type LessonResultContentProps = Pick<
  LessonResponse,
  'lessonAnswerKey' | 'letters' | 'sound' | 'lessonStory' | 'lessonId'
>;

const LessonResultContent = ({ lessonAnswerKey, letters, sound, lessonStory, lessonId }: LessonResultContentProps) => {
  const navigate = useNavigate();
  const completeLesson = useLessonComplete();
  const { userState } = useUserLSState();

  const handleFinish = () => {
    completeLesson.mutateAsync(
      { userId: userState?.userId ?? '', lessonId: lessonId ?? '' },
      {
        onSuccess: (res) => {
          navigate(URLS.lessonFinish, { replace: true });
        },
      }
    );
  };

  const wordsWithSoundIndexes = lessonAnswerKey.map((item) => `${item[0]}_${item[1]}`);

  return (
    <ContentLesson
      instruction={
        <Instruction
          text="Well done! Here are all the words with the sound"
          audio="lesson_instruction/l5.mp3"
          additionalComponent={<SoundCard sound={letters} />}
          additionalAudio={sound}
        />
      }
      actions={
        <Button style={{ margin: '0 auto' }} onClick={handleFinish}>
          Finish
        </Button>
      }
    >
      <Styled.Card>
        <Sentence>
          {lessonStory.map(({ text }, lessonStoryIdx) => {
            const words = getWordsFromText(text);

            return words.map((word, idx) => {
              const wordId = `${lessonStoryIdx}_${idx}`;
              const isSelected = !!userState?.selectedWordsIndexes?.includes(wordId);
              const hasSound = !isSelected && wordsWithSoundIndexes.includes(wordId);

              return (
                <Text
                  key={wordId}
                  as="span"
                  className={clsx('LessonResult__Word', {
                    isSelected,
                    hasSound,
                  })}
                >
                  {word}
                </Text>
              );
            });
          })}
        </Sentence>
        <Styled.Labels>
          <Styled.Label>
            <Circle diameter={50} backgroundColor="var(--indigo-100)" />
            <Text variant="sm">words you selected</Text>
          </Styled.Label>
          <Styled.Label>
            <Circle diameter={50} backgroundColor="var(--purple-a300)" />
            <Text variant="sm">
              words that also have{' '}
              <Text as="span" variant="sm" bold>
                {letters}
              </Text>{' '}
              sound
            </Text>
          </Styled.Label>
        </Styled.Labels>
      </Styled.Card>
    </ContentLesson>
  );
};

const LessonResult = () => {
  const { userState } = useUserLSState();
  const { data, isFetching, isError, refetch } = useGetLesson({ userId: userState?.userId ?? '' });

  const renderState = renderSwitch({
    loading: {
      condition: isFetching,
      render: <Loader />,
    },
    error: {
      condition: isError,
      render: (
        <ErrorCard style={{ marginTop: 60 }} onRetry={refetch} message="There was a problem retrieving this lesson" />
      ),
    },
    default: {
      condition: !!data,
      render: (
        <LessonResultContent
          lessonAnswerKey={data?.lessonContentsFormatted?.lessonAnswerKey ?? []}
          letters={data?.lessonContentsFormatted?.letters ?? ''}
          sound={data?.lessonContentsFormatted?.soundS3Path ?? ''}
          lessonStory={data?.lessonContentsFormatted?.lessonStory ?? []}
          lessonId={data?.lessonContentsFormatted.lessonId ?? ''}
        />
      ),
    },
  });

  return <>{renderState}</>;
};

export default LessonResult;
