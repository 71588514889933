import { Ref, forwardRef, useEffect, useImperativeHandle } from 'react';
import { useAudio } from 'react-use';
import type { HTMLMediaControls, HTMLMediaState } from 'react-use/lib/factory/createHTMLMediaHook';

import useMuteAudio from 'hooks/useMuteAudio';

export type AudioProps = React.MediaHTMLAttributes<HTMLAudioElement> & {
  localSrc?: string;
  speed?: number;
  ignoreAppMute?: boolean;
  onStateUpdate?: (state: HTMLMediaState, audioEl: HTMLAudioElement) => void;
};

export type AudioRef = {
  play: HTMLMediaControls['play'];
  pause: HTMLMediaControls['pause'];
  stop: () => void;
  seek: (time: number) => void;
  toggle: () => Promise<void>;
  getAudioElement: () => HTMLAudioElement;
  getState: () => HTMLMediaState;
  setSrc: (src: string) => void;
};

export const BASE_URL = '/audio/boy/';

const AudioBase = (
  {
    src = '',
    localSrc,
    muted = false,
    autoPlay = true,
    speed = 1,
    ignoreAppMute = false,
    onStateUpdate,
    ...restProps
  }: AudioProps,
  ref: Ref<AudioRef>
) => {
  const { audioMuted: appAudioMuted } = useMuteAudio();

  const mutedValue = ignoreAppMute ? muted : muted || appAudioMuted;

  const [audio, state, controls, audioElRef] = useAudio({
    ...restProps,
    muted: mutedValue,
    autoPlay: !mutedValue && autoPlay,
    src: localSrc ? `${BASE_URL}${localSrc}` : src,
  });

  useImperativeHandle(
    ref,
    () => ({
      play: () => {
        if (audioElRef.current) {
          audioElRef.current.playbackRate = speed;
        }
        return controls.play();
      },
      pause: () => controls.pause(),
      stop: () => {
        controls.pause();
        controls.seek(0);
      },
      seek: (time: number) => controls.seek(time),
      toggle: async () => {
        if (state.playing) controls.pause();
        if (state.paused) controls.play();
      },
      getAudioElement: () => audioElRef.current as HTMLAudioElement,
      getState: () => state,
      setSrc: (newSrc: string) => {
        if (!audioElRef.current) return;
        audioElRef.current.src = newSrc;
      },
    }),
    [audioElRef, controls, state, speed]
  );

  useEffect(() => {
    const audioEl = audioElRef.current;
    if (audioEl) {
      audioEl.playbackRate = speed;
    }
  }, [audioElRef, speed]);

  // If we mute app during audio is playing we should pause audio
  useEffect(() => {
    if (ignoreAppMute || state.paused) return;
    if (appAudioMuted) {
      controls.pause();
    }
  }, [appAudioMuted, controls, ignoreAppMute, state.paused]);

  useEffect(() => {
    onStateUpdate?.(state, audioElRef.current!);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  //! There is a weird issue with merged audio that causes only last chunk to replay. This is a hotfix
  // useEffect(() => {
  //   const audioEl = audioElRef.current;
  //   if (src && audioEl) {
  //     audioEl.onended = () => {
  //       audioEl.src = src;
  //     };
  //   }
  // }, [audioElRef, src]);

  return audio;
};

const Audio = forwardRef(AudioBase);

export default Audio;
