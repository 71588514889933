import { useNavigate } from 'react-router-dom';

import useUserLSState from 'hooks/useUserLSState';

import Button from 'components/ui/Button';
import ContentLuca from 'components/ui/ContentLuca/ContentLuca';

import { URLS } from 'components/App';
import Feed from 'components/Feed/Feed';

// import { AGAIN_ICON } from 'assets/icons';

import * as Styled from './styles';

const PHRASES = [
  {
    id: 0,
    text: 'Welcome to LUCA Playground!',
    audio: '',
  },
];

const Greeting = () => {
  const navigate = useNavigate();
  const { remove } = useUserLSState();

  const startAgain = () => {
    remove();
    navigate(URLS.enterName);
  };

  return (
    <ContentLuca
      actions={
        <Button style={{ margin: '0 auto' }} onClick={startAgain}>
          Start
        </Button>
      }
    >
      <Styled.GreetingContainer>
        <Feed phrases={PHRASES} />
      </Styled.GreetingContainer>
    </ContentLuca>
  );
};

export default Greeting;
