import { useLocalStorage, useMount } from 'react-use';

import { MenuItemStatuses } from 'types';

type UserState = {
  userId: string;
  name: string;
  firstName: string;
  lastName: string;
  role: string;
  email: string;
  ageRange: string;
  places: string[];
  characters: string[];
  numFreeStoriesGranted: number | null;
  numFreeStoriesLeft: number | null;
  menu: {
    story: MenuItemStatuses;
    lesson: MenuItemStatuses;
  };
  selectedWordsIndexes: string[];
};

export const initialState: UserState = {
  name: '',
  firstName: '',
  lastName: '',
  email: '',
  userId: '',
  role: '',
  ageRange: '',
  numFreeStoriesGranted: null,
  numFreeStoriesLeft: null,
  places: [],
  characters: [],
  menu: {
    story: 'ready',
    lesson: 'ready',
  },
  selectedWordsIndexes: [],
};

const useUserLSState = () => {
  const [userState, setUserState, remove] = useLocalStorage<UserState>('luca:userState', initialState);

  useMount(() => {
    setUserState({ ...initialState, ...userState });
  });

  const updateState = <TKey extends keyof UserState>(key: TKey, value: UserState[TKey]) =>
    setUserState({ ...userState, [key]: value } as UserState);

  const multiInfoUpdate = (infoValues: {
    firstName: string;
    lastName: string;
    role: string;
    userId?: string;
    numFreeStoriesGranted?: number;
    numFreeStoriesLeft?: number;
  }) =>
    setUserState({
      ...userState,
      firstName: infoValues.firstName,
      lastName: infoValues.lastName,
      role: infoValues.role,
      userId: infoValues.userId ?? '',
      numFreeStoriesGranted: infoValues.numFreeStoriesGranted ?? null,
      numFreeStoriesLeft: infoValues.numFreeStoriesLeft ?? null,
    } as UserState);

  const handleChangeStoriesLeft = (newNumStoriesLeft: number, numFreeStoriesGranted: number) => {
    setUserState({
      ...userState,
      numFreeStoriesLeft: newNumStoriesLeft,
      numFreeStoriesGranted: numFreeStoriesGranted,
    } as UserState);
  };

  const clearWordIndexes = () => {
    setUserState({ ...userState, selectedWordsIndexes: [] } as UserState);
  };

  return { userState, updateState, multiInfoUpdate, handleChangeStoriesLeft, remove, clearWordIndexes } as const;
};

export default useUserLSState;
