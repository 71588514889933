import { FormEvent, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import useAddUser from 'api/useAddUser';

import useUserLSState from 'hooks/useUserLSState';

import AgeOption from 'components/ui/AgeOption';
// import Audio from 'components/ui/Audio';
import Button from 'components/ui/Button';
import ContentCentered from 'components/ui/ContentCentered';
import FormGroup, { FormControl, FormControlTarget } from 'components/ui/FormGroup';
import Text from 'components/ui/Text';

import { URLS } from 'components/App';

import * as Styled from './styles';

const ageRanges = ['3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18'];

const SelectAgeRange = () => {
  const { userState, updateState } = useUserLSState();
  const [ageRange, setAgeRange] = useState(userState?.ageRange ?? '');
  const navigate = useNavigate();

  const addUserMutation = useAddUser();

  const isButtonDisabled = useMemo(() => !ageRange, [ageRange]);

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (isButtonDisabled) return;

    updateState('ageRange', ageRange);
    addUserMutation.mutateAsync(
      {
        age: Number(ageRange),
        email: userState?.email ?? '',
        firstName: userState?.firstName ?? '',
        lastName: userState?.lastName ?? '',
        userType: userState?.role ?? '',
      },
      {
        onSuccess: (res) => {
          console.log('res =', res);
          updateState('userId', res.userId);
          navigate(URLS.questions);
        },
      }
    );
  };

  const handleChange = (target: FormControlTarget) => {
    setAgeRange(target.value);
  };

  return (
    <ContentCentered>
      <Styled.SelectAgeRange onSubmit={handleSubmit}>
        <Text variant="lg" colorType="light">
          What is your reader’s age?
        </Text>
        <FormGroup className="SelectAgeRange__FormGroup" type="radio" onChange={handleChange}>
          {ageRanges.map((range: string) => (
            <FormControl defaultChecked={range === ageRange} name="ageRange" id={range} value={range} key={range}>
              <AgeOption className="SelectAgeRange__AgeOption">{range}</AgeOption>
            </FormControl>
          ))}
        </FormGroup>
        <Button type="submit" disabled={isButtonDisabled}>
          Continue
        </Button>
      </Styled.SelectAgeRange>
    </ContentCentered>
  );
};

export default SelectAgeRange;
