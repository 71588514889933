import { useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useBoolean, useMount, useStateList } from 'react-use';

import { AnswerEntity, InterestGroup } from 'types';

import useGetInterestsQuestions from 'api/useGetQuestions';
import useUpdateInterests from 'api/useUpdateInterests';

import useUserLSState from 'hooks/useUserLSState';

import AnswerOption from 'components/ui/AnswerOption';
import Audio, {
  AudioRef, //  BASE_URL
} from 'components/ui/Audio';
import ContentLuca from 'components/ui/ContentLuca';
import { FormControl, FormControlTarget } from 'components/ui/FormGroup';

import { URLS } from 'components/App';
import Feed from 'components/Feed';

import * as Styled from './styles';

// const getAudioFile = (index: number) => `questions/q${index + 1}.mp3`;

const Questions: React.FC<{ questions: InterestGroup[] }> = ({ questions }) => {
  const { userState, updateState } = useUserLSState();
  const navigate = useNavigate();
  const [, setIsFeedFull] = useBoolean(false);

  const { state, prev, next, currentIndex } = useStateList(
    questions?.map((step) => ({
      ...step,
      questions: step.questions.map((question) => ({
        ...question,
        text:
          step.id === 0 && question.id === 0
            ? question.text.replace('{}', userState?.firstName ?? 'Kid')
            : question.text,
        id: `${step.id}_${question.id}`,
      })),
    }))
  );

  const [answers, setAnswers] = useState<Record<string, { questionId: string; answers: string[] } | null>>({});
  const [interests, setInterests] = useState<Record<string, string[] | null>>({});

  const answerOptionAudioRef = useRef<AudioRef>(null);

  const updateUserInterests = useUpdateInterests();

  const stepHasAnswers = useMemo(
    () => !!state.answers?.length,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentIndex]
  );

  const goToPrevStep = () => {
    if (state.answers && (state.step === 'places' || state.step === 'characters')) {
      updateState(state.step, []);
      setInterests((prev) => ({ ...prev, [state.step]: [] }));
    }
    return currentIndex === 0 ? navigate(URLS.selectAgeRange) : prev();
  };

  const goToNextStep = () => {
    if (state.answers && (state.step === 'places' || state.step === 'characters')) {
      updateState(state.step, interests[state.step] ?? []);
    }

    if (currentIndex === questions.length - 1) {
      updateUserInterests.mutate(
        { userId: userState?.userId ?? '', answers: Object.values(answers) as AnswerEntity[] },
        {
          onSuccess: () => {
            navigate(URLS.menu);
          },
        }
      );
    } else {
      next();
    }
  };
  const playAnswerOption = (src: string) => {
    answerOptionAudioRef.current?.setSrc(src);
  };

  const handleNewChange =
    (step: typeof state) =>
    ({ name }: FormControlTarget) => {
      setAnswers((prev) => {
        const prevInterests = prev[step.step] ?? ({} as { questionId: string; answers: string[] });
        const selected = prevInterests?.answers?.includes(name);

        if (!selected) {
          // const option = toSnakeCase(name, { lowercase: true });
        }

        const newInterests = selected
          ? prevInterests?.answers?.filter((int) => int !== name)
          : prevInterests?.answers?.concat(name) || [name];
        const newOpt = {
          questionId: String(step.id),
          answers: newInterests,
        };
        // console.log('Object.entries(prev)', Object.values(prev));
        return { ...prev, [step.step]: newOpt };
      });
    };

  const handleFeedFull = () => setIsFeedFull(true);

  const nextButtonVisible = useMemo(
    () => {
      // const currentStep = STEPS[currentIndex]?.step;
      // const isStepHasInterests = !!interests?.[currentStep]?.length;
      // return (!stepHasAnswers && isFeedFull) || (!!Object.keys(interests).length && isStepHasInterests);
      return true;
    },
    // [currentIndex, interests, isFeedFull, stepHasAnswers]
    []
  );

  return (
    <ContentLuca
      onBack={goToPrevStep}
      onForward={nextButtonVisible ? goToNextStep : undefined}
      onForwardProps={{ disabled: updateUserInterests.isLoading }}
      onBackProps={{ disabled: currentIndex === 0 }}
    >
      <Feed phrases={state.questions} onFeedFull={handleFeedFull} key={`Feed_${currentIndex}`}>
        {stepHasAnswers && (
          <Styled.QuestionsDialog position="right">
            {state.answers?.flatMap((item) => item)?.length === 1 ? (
              <Styled.FormGroupSingle type="radio" onChange={goToNextStep}>
                <FormControl name="answer">
                  <AnswerOption>{state.answers?.flatMap((item) => item)?.at(0)?.text}</AnswerOption>
                </FormControl>
              </Styled.FormGroupSingle>
            ) : (
              <>
                <Styled.FormGroupMultiple type="checkbox" onChange={handleNewChange(state)}>
                  {state.answers
                    ?.flatMap((item) => item)
                    .map((answer) => (
                      <FormControl
                        defaultChecked={answers[state.step]?.answers?.includes(answer.text)}
                        name={answer.text}
                        key={answer.text}
                        onClick={() => {
                          if (!answers[state.step]?.answers?.includes(answer.text)) playAnswerOption(answer.audio);
                        }}
                      >
                        <AnswerOption>{answer.text}</AnswerOption>
                      </FormControl>
                    ))}
                </Styled.FormGroupMultiple>
                <Audio ignoreAppMute ref={answerOptionAudioRef} />
              </>
            )}
          </Styled.QuestionsDialog>
        )}
      </Feed>
    </ContentLuca>
  );
};

const RenderQuestions = () => {
  const { userState } = useUserLSState();

  const getInterests = useGetInterestsQuestions();
  const [questionsResponse, setQuestionsResponse] = useState<InterestGroup[]>();
  useMount(() => {
    getInterests.mutateAsync(
      { userId: userState?.userId ?? '' },
      {
        onSuccess: (response) => {
          setQuestionsResponse(response.interestGroupFormatted);
        },
      }
    );
  });
  if (!questionsResponse) {
    return null;
  }

  return <Questions questions={questionsResponse} />;
};

export default RenderQuestions;
