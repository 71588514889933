import { useMutation } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import camelCaseKeys from 'camelcase-keys';
import snakeCaseKeys from 'snakecase-keys';

import { apiPlaygroundClient } from 'lib/apiClient';

import { TUserFindPayload, TUserFindResponse } from 'types';

const findUser = async (payload: TUserFindPayload) => {
  const res = await apiPlaygroundClient.post<TUserFindPayload, AxiosResponse<TUserFindResponse>>(
    'user/find/',
    snakeCaseKeys(payload, { deep: true })
  );
  return camelCaseKeys(res.data, { deep: true });
};

const useFindUser = () => useMutation(findUser);

export default useFindUser;
