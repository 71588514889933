import { useMutation } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import camelCaseKeys from 'camelcase-keys';
import snakeCaseKeys from 'snakecase-keys';

import { apiPlaygroundClient } from 'lib/apiClient';

import { TLessonCompletePayload } from 'types';

const lessonComplete = async (payload: TLessonCompletePayload) => {
  const res = await apiPlaygroundClient.post<TLessonCompletePayload, AxiosResponse<{ updated: string }>>(
    'user/update/lesson_completion/',
    snakeCaseKeys(payload, { deep: true })
  );
  return camelCaseKeys(res.data, { deep: true });
};

const useLessonComplete = () => useMutation(lessonComplete);

export default useLessonComplete;
